var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "mew-component--create--mnemonic-phrase",
      staticStyle: { "max-width": "800px" },
    },
    [
      _c("mew-stepper", {
        staticClass: "mx-md-0",
        attrs: { items: _vm.steppers, "on-step": _vm.step },
        scopedSlots: _vm._u(
          [
            _vm.step === 1
              ? {
                  key: "stepperContent1",
                  fn: function () {
                    return [
                      _c(
                        "div",
                        {
                          staticClass: "subtitle-1 font-weight-bold grey--text",
                        },
                        [_vm._v("STEP 1.")]
                      ),
                      _c(
                        "div",
                        { staticClass: "headline font-weight-bold mb-5" },
                        [_vm._v("Write down these words")]
                      ),
                      _c(
                        "div",
                        { staticClass: "d-flex align-center justify-end pb-4" },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "greenPrimary--text cursor--pointer d-flex align-center mr-2 pa-2",
                              on: { click: _vm.setPhrase },
                            },
                            [
                              _c(
                                "v-icon",
                                {
                                  staticClass: "mr-1",
                                  attrs: { small: "", color: "greenPrimary" },
                                },
                                [_vm._v("mdi-sync")]
                              ),
                              _c("div", { staticClass: "font-weight-medium" }, [
                                _vm._v("Update"),
                              ]),
                            ],
                            1
                          ),
                          _c("v-select", {
                            staticStyle: { "max-width": "150px" },
                            attrs: {
                              "hide-details": "",
                              dense: "",
                              "item-text": "name",
                              "item-value": "value",
                              items: _vm.mnemonicOptions,
                              label: "",
                              outlined: "",
                            },
                            model: {
                              value: _vm.phraseSize,
                              callback: function ($$v) {
                                _vm.phraseSize = $$v
                              },
                              expression: "phraseSize",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "phrase-block",
                        { staticClass: "mb-8 CreateWalletMnemonicTable" },
                        [
                          _c("mnemonic-phrase-table", {
                            attrs: { data: _vm.phrase },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "mt-10" },
                        [
                          _c("mew-expand-panel", {
                            attrs: {
                              "has-dividers": true,
                              "is-toggle": true,
                              "interactive-content": true,
                              "panel-items": [{ name: "Add Extra Word" }],
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "panelBody1",
                                  fn: function () {
                                    return [
                                      _c(
                                        "div",
                                        { staticClass: "px-5" },
                                        [
                                          _c("mew-input", {
                                            attrs: {
                                              label: "Extra word",
                                              placeholder: "Extra word",
                                            },
                                            model: {
                                              value: _vm.extraWord,
                                              callback: function ($$v) {
                                                _vm.extraWord = $$v
                                              },
                                              expression: "extraWord",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              false,
                              2169908667
                            ),
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "MnemonicWroteThemDown d-flex justify-center mt-6",
                        },
                        [
                          _c("mew-button", {
                            attrs: {
                              title: "I wrote them down",
                              "btn-size": "xlarge",
                              "has-full-width": false,
                            },
                            nativeOn: {
                              click: function ($event) {
                                return _vm.updateStep(2)
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                }
              : null,
            _vm.step === 2
              ? {
                  key: "stepperContent2",
                  fn: function () {
                    return [
                      _c(
                        "div",
                        {
                          staticClass: "subtitle-1 font-weight-bold grey--text",
                        },
                        [_vm._v("STEP 2.")]
                      ),
                      _c("div", { staticClass: "headline font-weight-bold" }, [
                        _vm._v("Verification"),
                      ]),
                      _c("div", { staticClass: "mb-5" }, [
                        _vm._v(" " + _vm._s(_vm.stepTwoText) + " "),
                      ]),
                      _c(
                        "v-sheet",
                        {
                          staticClass: "MnemonicRadioOptions mx-auto",
                          attrs: { "max-width": "600px" },
                        },
                        [
                          _vm._l(
                            _vm.generatedVerification,
                            function (item, idx) {
                              return _c(
                                "v-radio-group",
                                {
                                  key: idx + "verification",
                                  staticClass: "radio-group pa-5",
                                  attrs: {
                                    "hide-details": "",
                                    mandatory: "",
                                    row: "",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "label",
                                        fn: function () {
                                          return [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "mew-heading-3 mb-3 mb-sm-0",
                                                staticStyle: {
                                                  "min-width": "30px",
                                                  "line-height": "25px",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.getOnlyKey(item) + 1
                                                    ) +
                                                    ". "
                                                ),
                                              ]
                                            ),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                  model: {
                                    value:
                                      _vm.validateMnemonicValues[
                                        _vm.getOnlyKey(item)
                                      ],
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.validateMnemonicValues,
                                        _vm.getOnlyKey(item),
                                        $$v
                                      )
                                    },
                                    expression:
                                      "validateMnemonicValues[getOnlyKey(item)]",
                                  },
                                },
                                [
                                  _c(
                                    "v-row",
                                    _vm._l(
                                      _vm.getEntries(item),
                                      function (entries, id) {
                                        return _c(
                                          "v-col",
                                          {
                                            key: entries + id,
                                            staticClass: "Options",
                                            attrs: { cols: "12", sm: "4" },
                                          },
                                          [
                                            _c("v-radio", {
                                              attrs: {
                                                label: entries,
                                                value: entries + "_" + id,
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              )
                            }
                          ),
                          _vm.extraWord && _vm.extraWord !== ""
                            ? _c("mew-input", {
                                staticClass: "mt-10 mb-3",
                                attrs: {
                                  label: "Confirm extra word",
                                  placeholder: "Please confirm your extra word",
                                },
                                model: {
                                  value: _vm.extraWordVerification,
                                  callback: function ($$v) {
                                    _vm.extraWordVerification = $$v
                                  },
                                  expression: "extraWordVerification",
                                },
                              })
                            : _vm._e(),
                        ],
                        2
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex flex-column flex-md-row justify-center mt-6",
                        },
                        [
                          _c("mew-button", {
                            staticClass: "mx-md-1 my-1",
                            attrs: {
                              title: "Back",
                              "btn-size": "xlarge",
                              "btn-style": "outline",
                            },
                            nativeOn: {
                              click: function ($event) {
                                return _vm.updateStep(1)
                              },
                            },
                          }),
                          _c("mew-button", {
                            staticClass: "CreateMnemonicVerify mx-md-1 my-1",
                            attrs: {
                              title: "Verify",
                              "btn-size": "xlarge",
                              disabled: !_vm.canVerify,
                            },
                            nativeOn: {
                              click: function ($event) {
                                return _vm.verify.apply(null, arguments)
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                }
              : null,
            _vm.step === 3
              ? {
                  key: "stepperContent3",
                  fn: function () {
                    return [
                      _c(
                        "div",
                        { staticClass: "d-flex align-center" },
                        [
                          _c(
                            "div",
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "subtitle-1 font-weight-bold grey--text",
                                },
                                [_vm._v("STEP 3.")]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "headline font-weight-bold mb-3",
                                },
                                [_vm._v("Well done!")]
                              ),
                              _c("p", { staticClass: "mb-6" }, [
                                _vm._v(
                                  " You are now ready to take advantage of all that Ethereum has to offer! Access with mnemonic phrase should only be used in an offline setting "
                                ),
                              ]),
                              _c("v-img", {
                                staticClass:
                                  "d-block d-sm-none mx-auto mt-12 mb-12",
                                attrs: {
                                  "max-width": "170px",
                                  src: require("@/assets/images/icons/icon-keystore-mew.png"),
                                },
                              }),
                              _c(
                                "div",
                                { staticClass: "d-flex flex-column" },
                                [
                                  _c("mew-button", {
                                    staticClass:
                                      "CreateMnemonicAccessWallet mb-5",
                                    attrs: {
                                      title: "Access Wallet",
                                      "btn-size": "xlarge",
                                      "has-full-width": false,
                                    },
                                    nativeOn: {
                                      click: function ($event) {
                                        return _vm.goToAccess.apply(
                                          null,
                                          arguments
                                        )
                                      },
                                    },
                                  }),
                                  _c("mew-button", {
                                    attrs: {
                                      title: "Create Another Wallet",
                                      "has-full-width": false,
                                      "btn-style": "transparent",
                                    },
                                    nativeOn: {
                                      click: function ($event) {
                                        return _vm.createAnotherWallet()
                                      },
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c("v-img", {
                            staticClass: "d-none d-sm-block ml-8",
                            attrs: {
                              "max-width": "250px",
                              src: require("@/assets/images/icons/icon-keystore-mew.png"),
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                }
              : null,
          ],
          null,
          true
        ),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }